import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';
import Page from '../../components/Page';

import { RegionDetail } from "../../sections";

// ----------------------------------------------------------------------

export default function RegionDetailPage({regionId,regionLabel=undefined}) {
    const { themeStretch } = useSettings();

    if(regionLabel===undefined) regionLabel=regionId;

    return (
        <DashboardLayout>
            <Page title={`Region Detail: ${regionLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <RegionDetail regionId={regionId} regionLabel={regionLabel}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
